<template>
  <div
    :id="row.fields.row_id ? row.fields.row_id : ''"
    class="modal modal--row"
    :class="{
      'modal--inner-layout-bottom':
        row.fields.modalContentsLayout &&
        row.fields.modalContentsLayout === 'Bottom',
      'modal--inner-layout-center':
        row.fields.modalContentsLayout &&
        row.fields.modalContentsLayout === 'Center',
      'modal--inner-layout-top':
        row.fields.modalContentsLayout &&
        row.fields.modalContentsLayout === 'Top',
      'modal-custom-form': hasGatedForm,
    }"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen"
      @click.prevent="closeModal"
    ></div>
    <div class="modal__inner overflow-y-auto" :class="outerWrapperClasses">
      <span
        class="modular-modal-close absolute right-[20px] top-[20px]"
        @click.prevent="closeModal"
        ><svg
          width="29"
          height="30"
          viewBox="0 0 29 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="28.3536"
            y1="1.35355"
            x2="0.353553"
            y2="29.3536"
            stroke="white"
          />
          <line
            x1="0.353553"
            y1="0.646447"
            x2="28.3536"
            y2="28.6464"
            stroke="white"
          />
        </svg>
      </span>
      <div :class="innerWrapperClasses">
        <div
          v-if="row.fields.content && row.fields.content.length > 0"
          ref="contentWrap"
          :class="rowEntryWrapperClasses"
        >
          <template v-for="(entry, index) in row.fields.content" :key="index">
            <ModularRowContentEntries :row="row" :entry="entry" />
          </template>
        </div>
      </div>
      <div v-if="isVideo" class="hero--video">
        <VideosTheVideo :video="row.backgroundImage.fields" />
      </div>
    </div>

    <LinksContentfulLink v-if="row.sys.id" :id="row.sys.id" label="row" />
  </div>
</template>

<script setup>
  import { fadeOut } from '~/utils/html-helpers';

  const props = defineProps({
    row: {
      type: Object,
      required: true,
    },
    isVideo: {
      type: Boolean,
      required: true,
    },
    innerWrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
    outerWrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
    rowEntryWrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
  });

  const { $emitter } = useNuxtApp();
  const hasGatedForm = ref(false);
  const gatedFormSubmitted = ref(false);

  provide('row:modalId', props.row.fields.row_id);

  $emitter.on('hasGatedForm', (id) => {
    if (id === props.row.fields.row_id) hasGatedForm.value = true;
  });
  $emitter.on('gatedFormSubmitted', (value) => {
    gatedFormSubmitted.value = value;
    closeModal();
  });

  const closeModal = () => {
    $emitter.emit('closeModal');
    if (hasGatedForm.value === false || gatedFormSubmitted.value === true) {
      let modalParent;
      if (props.row.fields.row_id) {
        modalParent = document.querySelector(`#${props.row.fields.row_id}`);
      } else {
        modalParent = event.target.closest('.modal');
      }
      fadeOut(modalParent);
      document.querySelector('html').classList.remove('overflow-y-hidden');
    }
  };
</script>

<style lang="scss" scoped>
  #contact-us-modal {
    :deep(.columns-wrapper) {
      @apply pt-1 md:pt-0;
    }
    .modal__inner {
      @apply h-[90%] w-[90%] md:h-max md:w-[60vw] short:h-[90%];

      :deep(h3) {
        @apply px-1 md:px-0;
      }
      .row-container {
        .columns-wrapper {
          @apply py-[30px] pb-0;
        }
      }
    }
  }
  .modal--inner-layout-center,
  .modal--inner-layout-top,
  .modal--inner-layout-bottom {
    .row-container {
      @apply h-full;
      .columns-wrapper {
        @apply h-full;
      }
    }
  }
  .modal--inner-layout-center {
    .row-container {
      .columns-wrapper {
        @apply content-center;
      }
    }
  }
  .modal--inner-layout-top {
    .row-container {
      .columns-wrapper {
        @apply content-start;
      }
    }
  }
  .modal--inner-layout-bottom {
    .row-container {
      .columns-wrapper {
        @apply content-end;
      }
    }
  }
  .modal-custom-form {
    .modal__inner {
      @apply max-h-fit w-fit max-w-[560px] border-t-[5px] border-blue;
    }
    .modular-modal-close {
      @apply hidden;
    }
    .row-container {
      @apply mx-0 px-[30px];
    }
  }
</style>
